import React from 'react'
import WebriQForm from '@webriq/gatsby-webriq-form'
import { Form } from 'react-bootstrap'
import './form.css'

class WebriqForm extends React.Component {

  componentDidMount() {
    function handleKeyUp(e) {
      if (e.target.value.length === 0) {
        this.classList.remove("active");
      } else {
        this.classList.add("active");
      }
    }
    var formField = document.getElementsByClassName('form-group')
    for (var i = 0; i < formField.length; i++) {
      formField[i].addEventListener('keyup', handleKeyUp)
    }
    for(var e = 0; e < formField.length; e++){
      formField[e].addEventListener('change', handleKeyUp);
    }
  }
    

  render() {
    const { ...props } = this.props;
    let choosenform;
    if(this.props.webriqform === "true") {
      choosenform = (
        <WebriQForm method="POST" {...props}>
          { this.props.children }
        </WebriQForm>
      )
    }
    else {
      choosenform = (
        <Form {...props}>
          {this.props.children}
        </Form>
      )
    }
    return (
     <>
      {choosenform}
     </>
    )
  }
}

export default WebriqForm;